.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slider {
  width: 75%;
  height: 75%;
}

a {
  color: white;
  text-decoration: none;
}
/* BACKGROUND */
.background {
  position: relative;
  background-color: #e3d5ca;
}

.background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 37, 255, 0.8);
  filter: brightness(0.5); 
  z-index: -1; 
}

.background h5,
.background p {
  position: relative; 
  z-index: 1; 
}

.kep-sotet::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fekete átlátszó háttér */
  z-index: -1;
}

.kor-kep {
  width: 300px; /* A kép szélessége */
  height: 300px; /* A kép magassága */
  background-image: url("./img/elokep-halvany.jpg"); /* Helyettesítsd az útvonalat a kép elérési útvonalával */
  background-size: cover; /* Az egész képet jeleníti meg a háttérben */
  background-position: center; /* Középre igazítja a képet */
  border-radius: 50%; /* Kerekített szélek */
  overflow: hidden; /* A tartalom kívül eső részei el vannak rejtve */
  border: 8px solid transparent; /* Átlátszó szegély */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Árnyék, ami elmosódást ad a kép szélén */
}

.row {
  margin: 0px !important;
}

.kep-egyedi {
  border-radius: 75%;
}

.zaszlok {
  width: 9rem;
  height: 2.5rem;
  z-index: 2;
}

.zaszlo-img {
  width: 2.5rem;
  height: 2rem;
}

.egyedi-asd {
  position: absolute;
  bottom: -25;
  width: 100%;
}

.link {
  text-decoration: underline;
  color: rgb(94, 94, 204);
}

.custom-border {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

html {
  background-color: #e3d5ca;
}

.home-egyedi {
  height: 100%;
  background-color: #e3d5ca;
}

.container-beige {
  background-color: #e3d5ca;
}

.kitolto {
  height: 105px;
  background-color: #e3d5ca;
}

.teljes {
  background-color: #e3d5ca;
}

.bg-dark {
  background-color: #5e503f !important;
}

.szoveg {
  font-family: "Great Vibes", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 55px;
  color: #1b263b;
}

/* body {
  -webkit-user-select: none;
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none;
} */
